$admin: false;

/* purgecss start ignore */
/* Settings */
@import 'global/fonts';
@import 'settings/colors';
@import 'settings/settings';
@import 'settings/variables';
@import 'settings/mixins';

/* Global */
@import 'global/reset';
@import 'global/print';
@import 'global/screen-reader-text';
@import 'global/animations';
@import 'global/global';
@import 'global/typography';
/* purgecss end ignore */

/* Foundation; The XY Grid is included by default; Documentation at <http://foundation.zurb.com/sites/docs/> */
@import 'foundation';
@include foundation-xy-grid-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;

/* Font Awesome 5 Pro; Uncomment if you want to use it */
@import 'fontawesome-pro/scss/fontawesome';
//@import 'fontawesome-pro/scss/solid';
//@import 'fontawesome-pro/scss/regular';
//@import 'fontawesome-pro/scss/light';
//@import 'fontawesome-pro/scss/duotone';
//@import 'fontawesome-pro/scss/brands';

/* purgecss start ignore */
/* Components */
@import 'components/button';
@import 'components/form';
@import 'components/switch';
@import 'components/message';
@import 'components/table';
@import 'components/header';
@import 'components/footer';
@import 'components/consent-bar';
@import 'front/modal';

/* Modules */
@import 'modules/gravityforms';
/* purgecss end ignore */

/* Shared (styles used in both website and editor) */
//@import 'shared';
